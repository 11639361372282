import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faClock, faFolderOpen} from "@fortawesome/free-regular-svg-icons"
import {faChevronLeft, faChevronRight, faCheckSquare} from "@fortawesome/free-solid-svg-icons"

// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"

// import useContentfulImage from "../utils/useContentfulImage"


import Seo from "../components/seo"

const options = {
    renderText: text => {
        return text.split('\n').reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
    },
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => (
            <h2>
                <FontAwesomeIcon icon={faCheckSquare} />
                {children}
            </h2>
        ),
        // [BLOCKS.EMBEDDED_ASSET]: node => (
        //     <GatsbyImage
        //         image={useContentfulImage(node.data.target.fields.file["ja-JP"].url)}
        //         alt={
        //             node.data.target.fields.description
        //             ? node.data.target.fields.description["ja-JP"]
        //             : node.data.target.fields.title["ja-JP"]
        //         } />
        // ),
    }

}

const blogpostTemplate = ({data, pageContext, location}) => (
    <Layout>

        <Seo
            pagetitle={`${data.contentfulBlogPost.title} | ニュース・ブログ`}
            pagedesc={data.contentfulBlogPost.outline.outline}
            pagepath={location.pathname}
            blogimg={`https:${data.contentfulBlogPost.eyecatch.file.url}`}
            pageimgw={data.contentfulBlogPost.eyecatch.file.details.image.width}
            pageimgh={data.contentfulBlogPost.eyecatch.file.details.image.height}
        />

        <div className="eyecatch">
            <figure>
                <GatsbyImage
                    image={data.contentfulBlogPost.eyecatch.gatsbyImageData}
                    alt={data.contentfulBlogPost.eyecatch.description}
                    style={{height:"100%"}} />
            </figure>
        </div>

        <article className="content">
            <div className="container">
                <h1 className="bar">{ data.contentfulBlogPost.title }</h1>

                <aside className="info">
                    <time dateTime={ data.contentfulBlogPost.publishDate }>
                        <FontAwesomeIcon icon={faClock} />
                        { data.contentfulBlogPost.publishDateJP }
                    </time>

                    <div className="cat">
                        <FontAwesomeIcon icon={faFolderOpen} />
                        <ul>
                            {data.contentfulBlogPost.category.map(cat => (
                                <li className={cat.categorySlug} key={cat.id}>
                                    <Link to={`/cat/${cat.categorySlug}/`}>{cat.category}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </aside>

                <div className="postbody">
                    {/*{ documentToReactComponents(data.contentfulBlogPost.content.json, options) }*/}
                    { renderRichText(data.contentfulBlogPost.content, options )}
                </div>

                <ul className="postlink">
                    {(pageContext.next && (
                        <li className="prev">
                            <Link to={`/post/${pageContext.next.slug}/`} rel="prev">
                                <FontAwesomeIcon icon={faChevronLeft} />
                                <span>{pageContext.next.title}</span>
                            </Link>
                        </li>
                    )) || <li></li> }

                    {(pageContext.previous && (
                        <li className="prev">
                            <Link to={`/post/${pageContext.previous.slug}/`} rel="next">
                                <span>{pageContext.previous.title}</span>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </Link>
                        </li>
                    )) || <li></li> }
                </ul>

            </div>
        </article>

    </Layout>
)
export default blogpostTemplate;

export const query = graphql`
    query($id: String!) {
        contentfulBlogPost(id: { eq: $id }){
            title
            publishDateJP : publishDate(formatString: "YYYY年MM月DD日")
            publishDate
            category {
              category
              categorySlug
              id
            }
            outline{
                outline
            }
            eyecatch{
              description
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                width: 500
                layout: FULL_WIDTH
              )
              file {
                details {
                  image {
                    height
                    width
                  }
                }
                url
              }
            }
            content{
                raw
                references {
                  contentful_id
                  gatsbyImageData(layout: FULL_WIDTH)
                  title
                  description
               }
            }
        }
    }
`